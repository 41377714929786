import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import useFilterBy from '../hooks/filter'
import Link from './link'
import Spacer from './spacer'
export default function ProjectsList({ projects = [], filters = [] }) {
	const projectFilters = [{ name: 'all' }, ...filters]
	const { filtered, filter: currentFilter, filterBy } = useFilterBy(projects)

	return (
		<div className='p4020 m-pb80'>
			<div className='mb30 pb20 bb1'>
				{projectFilters.map((filter, index) => {
					return <FilterButton key={index} active={compareFilter(filter, currentFilter)} number={countProjects(projects, filter.name)} onClick={() => filterBy(filter.name)} filter={filter} />
				})}
			</div>
			<div className='project--grid'>
				{filtered.map(project => {
					return <Project key={project.name} project={project} />
				})}
			</div>
			<Spacer />
		</div>
	)
}

const Project = ({ project }) => {
	return (
		<Link className='link flex' to={'/projects/' + project.slug}>
			<div className='project-card w-100 flex flex-col'>
				<div className='ratio-2-1 pos-rel overflow bg-grey'>
					<GatsbyImage className='bg-image image--zoom' image={project.heroImage.gatsbyImageData} alt='Project Image' />
				</div>
				<div className='info'>
					<p className='uppercase book m0 mt10'>{project.name}</p>
					<p className='signifier mt10 mb0' dangerouslySetInnerHTML={{ __html: project?.developer }} />
					<p className='signifier mt5 mb40' dangerouslySetInnerHTML={{ __html: project?.stats[0]?.value }} />
				</div>
				<div className='mta bb1 w-100'>
					<p className='book small m0 pb10'>{project.projectStatus.name}</p>
				</div>
			</div>
		</Link>
	)
}

// function Project({ project }) {
// 	const projectLink = useGetProjectLink(project)
// 	return (
// 		<div className='project--single pointer-none grid grid-1-2 mb30 link m-flex m-flex-col-r m-mb40 m-gap-y-0'>
// 			<div className='pr70'>
// 				<div className='pos-sticky pointer-all'>
// 					<Link className='link' to={projectLink}>
// 						<h4 className='h4 mb0 mt0 will--underline m-underline m-pt10   '>{project.name}</h4>
// 						<h4 className='h4 mt0 op50 m-mb0'>{project.location}</h4>
// 					</Link>
// 				</div>
// 			</div>
// 			<Link className='link pointer-all' to={projectLink}>
// 				<div className='ratio-150-1 overflow-hidden pos-rel'>
// 					<div className='bg-image'>
// 						<GatsbyImage className='h100 w-100 image--zoom' alt='Project Image' image={project?.heroImage?.gatsbyImageData} />
// 					</div>
// 				</div>
// 			</Link>
// 		</div>
// 	)
// }

function FilterButton({ filter, number, active, ...props }) {
	return (
		<button className={'button book filter--button captialcase mr40 link pb5 m-mr15    ' + (active ? 'ul-link' : 'op50')} {...props}>
			{filter.name}
			<sup>{number}</sup>
		</button>
	)
}

function countProjects(projects, name) {
	if (name === 'all') {
		return projects.length
	}
	return projects.filter(project => project?.projectStatus?.name === name).length
}

function compareFilter(filter, currentFilter) {
	if (currentFilter === '') {
		return filter.name === 'all'
	}
	return filter.name === currentFilter
}
