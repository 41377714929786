import { useLayoutEffect, useState } from "react";

export default function useQueryString(key, fn = () => { }) {
    const [keyValue, setKeyValue] = useState('')

    useLayoutEffect(() => {
        const params = (new URL(document.location)).searchParams;
        if (params.get(key) !== null) {
            setKeyValue(params.get(key))
        }  
    }, [key])

    function setParam(name) {
        const searchParams = new URLSearchParams({ [key]: name })
        setKeyValue(name)
        window.history.replaceState(null, null, `?${searchParams.toString()}`)
    }




    return { keyValue, setParam }

}

