import { graphql } from 'gatsby'
import * as React from 'react'
import CTA from '../components/ctaSmall'
import Enquiry from '../components/enquiry'
import Layout from '../components/layout'
import ParallaxBanner from '../components/parallaxBanner'
import ProjectsList from '../components/projectsList'
import SEO from '../components/seo'
import Spacer from '../components/spacer'

class ProjectsPage extends React.Component {
	render() {
		const data = this.props.data
		const projects = data.projects.nodes
		const filters = data.status.nodes
		const page = data.allDatoCmsProjectsPage.nodes[0]
		const enquiry = data.enquiry
		return (
			<Layout>
				<SEO title='Projects' />
				<ParallaxBanner image={page.heroImage} title='Projects' version='true' text={page.heroTitle} />
				<div className='bg-black'>
					<Spacer className='m-h-40' />
					<ProjectsList projects={projects} filters={filters} />
					<Spacer className='m-hide' />
					<Spacer className='m-hide' />
					<Enquiry subtitle={enquiry.contactSubtitle} subtitleTwo={enquiry.contactSubtitleTwo} title={enquiry.contactTitle} titleTwo={enquiry.contactTitleTwo} />
					<Spacer className='m-hide' />
					<Spacer className='m-hide'/>
					<Spacer />
					<CTA heading={page.heading} link={page.linkedPage} text={page.linkedText} />
				</div>
			</Layout>
		)
	}
}

export default ProjectsPage

export const query = graphql`
	query Projects {
		enquiry: datoCmsHomepage {
			contactTitle
			contactSubtitle
			contactTitleTwo
			contactSubtitleTwo
		}
		allDatoCmsProjectsPage {
			nodes {
				linkedPage
				linkedText
				heading
				heroTitle
				heroImage {
					gatsbyImageData
				}
			}
		}
		projects: allDatoCmsProject(sort: { fields: position }) {
			nodes {
				year
				slug
				name
				location
				developer
				stats {
					id
					name
					value
				}
				projectStatus {
					name
				}
				heroImage {
					gatsbyImageData
				}
			}
		}
		status: allDatoCmsStatus {
			nodes {
				name
			}
		}
	}
`
