import _ from 'lodash'
import { useEffect, useState, useCallback } from 'react'
import useQueryString from './query-string'

export default function useFilterBy(toFilter) {

    const [filter, setFilter] = useState('all')
    const [filtered, setFiltered] = useState(toFilter)
    const { setParam, keyValue } = useQueryString('filter')

    const filterBy = useCallback((name) => {
        setParam(name)
    }, [setParam])

    useEffect(() => {
        setFilter(keyValue)
        if (filterIsAll(keyValue)) {   
            setFiltered(toFilter)
            return
        }

        if (keyValue) {
            setFiltered(_.filter(toFilter, { projectStatus: { name: keyValue } }))
            return 
        }
    }, [keyValue,toFilter])

    return { filtered, filter, filterBy }
}

function filterIsAll(name) {
    return (name === '*' || name === 'all' || name === null)
}


